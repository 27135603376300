@mixin white-links {
    --link: #F4F4F4;
    --link_hover: #F4F4F4;
    --link_active: #F4F4F4;
    --link_border: #F4F4F4;
}

@mixin logo-link {
    --link: #7D48ED;
    --link_hover: #5621C7;
    --link_active: #3A0C9C;
    --link_border: #7D48ED;
}

.dark {
    --text-background: rgba(38, 38, 38, 0.66);
    --link: #B491FF;
    --link_hover: #D1BCFF;
    --link_active: #ffffff;
    --link_border: #ffffff;
    --bg: #161616;
    --text: #F4F4F4;
    --text_label: #C6C6C6;
    --text_placeholder: #797979;
    --text_disabled: #595959;
    --layer-contrast_bg: #262626;
    --layer-contrast_bg_hover: #393939;
    --layer-contrast_text: #F4F4F4;
    --layer-contrast_text-shadow: 1px 1px #262626;
    --layer-1_bg: #262626;
    --layer-1_bg-hover: #333333;
    --accent-1: #6F6F6F;
    --accent-2: #393939;
    --interactive-secondary: #6F6F6F;
    --interactive-secondary_hover: #5E5E5E;
    --interactive-secondary_active: #393939;
    --interactive-secondary_border: #FFFFFF;
    --brand: #7D48ED;
    --nav-shadow: rgba(0, 0, 0, 0.5);
    --screenshot-shadow: 0 0 16px rgba(0, 0, 0, 0.44);
    ::selection {
        background: #7D48ED; 
    }
    ::-moz-selection {
        background: #7D48ED; 
    }
}
.light {
    --text-background: rgba(244, 244, 244, 0.66);
    --link: #7D48ED;
    --link_hover: #5621C7;
    --link_active: #3A0C9C;
    --link_border: #7D48ED;
    --bg: #FFFFFF;
    --text: #161616;
    --text_label: #595959;
    --text_placeholder: #9C9C9C;
    --text_disabled: #C5C5C5;
    --layer-contrast_bg: #161616;
    --layer-contrast_bg_hover: #262626;
    --layer-contrast_text: #F4F4F4;
    --layer-contrast_text-shadow: 1px 1px #161616;
    --layer-1_bg: #F4F4F4;
    --layer-1_bg-hover: #E8E8E8;
    --accent-1: #8D8D8D;
    --accent-2: #161616;
    --interactive-secondary: #393939;
    --interactive-secondary_hover: #474747;
    --interactive-secondary_active: #f89595;
    --interactive-secondary_border: #7D48ED;
    --nav-shadow: rgba(0, 0, 0, 0.15);
    --brand: #7D48ED;
    --screenshot-shadow: 0 0 16px rgba(0, 0, 0, 0.17);
    ::selection {
        background: #E0D2FF;
    }
    ::-moz-selection {
        background: #E0D2FF;
    }
}

@mixin dark-contrast-selection {
    ::selection {
        background: #E0D2FF!important;
    }

    ::-moz-selection {
        background: #E0D2FF!important;
    }
}

@mixin light-contrast-selection {
    ::selection {
        background: #7D48ED!important;
    }
    
    ::-moz-selection {
        background: #7D48ED!important;
    }
}

@mixin black-selection {
    ::selection {
        background: #000000 !important;
    }

    ::-moz-selection {
        background: #000000 !important;
    }
}

@mixin white-selection {
    ::selection {
        background: #FFFFFF !important;
    }

    ::-moz-selection {
        background: #FFFFFF !important;
    }
}
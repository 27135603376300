@mixin link {
    display: inline-flex;
    color: var(--link);
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, 0);
    position: relative;

    svg,
    svg path {
        width: 1rem;
        height: 1rem;
        margin-left: 0.75rem;
        fill: var(--link);
    }

    &:hover {
        text-decoration: underline;
        color: var(--link_hover);

        svg,
        svg path {
            fill: var(--link_hover);
        }
    }

    &:focus {
        border: 1px solid var(--link_border);
    }

    &:active {
        color: var(--link_active);

        svg,
        svg path {
            fill: var(--link_active);
        }
    }

    &:visited {
        text-decoration: none;
    }
}
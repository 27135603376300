@use '../styles/Typography.module.scss';

.button-content {
    position: relative;
    z-index: 1;
}

.button {
    @include Typography.button;
    color: var(--interactive-text);
    display: inline-flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    margin: 0;
    position: relative;
    text-decoration: none;
    box-sizing: border-box;
    min-width: 12rem;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    svg {
        height: 1.25rem;
        width: 1.25rem;
        fill: var(--interactive-text);
        path {
            fill: var(--interactive-text);
        }
    }
    .button-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
        height: 1.25rem;
        width: 1.25rem;
        position: relative;
        z-index: 1;
    }
    &.icon {
        height: 3.375rem;
        width: 3.375rem;
        .button-icon {
            margin-left: 0;
            height: initial;
            width: initial;
        }
        min-width: 3.375rem;
        justify-content: center;
        align-items: center;

        &::after {
            border: 1px solid transparent;
        }

        &::before {
            background-color: transparent;
        }
        svg {
            height: 1.25rem;
            width: 1.25rem;
            position: relative;
            z-index: 102;
        }
    }

    &::before {
        cursor: pointer;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--interactive);
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: 0.2s ease-out background-color;
    }

    &:focus {
        &::before {
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            top: 3px;
            left: 3px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 2px solid var(--interactive_border);
        }
    }

    &:hover::before {
        background-color: var(--interactive_hover);
    }

    &:active {
        &::before {
            background-color: var(--interactive_active);
        }
    }

    &.secondary {
        &::before {
            background-color: var(--interactive-secondary);
        }

        &:focus {
            &::after {
                border: 2px solid var(--interactive-secondary_border);
            }
        }

        &:hover::before {
            background-color: var(--interactive-secondary_hover);
        }

        &:active {
            &::before {
                background-color: var(--interactive-secondary_active);
            }
        }
    }

    &.white {
        color: var(--interactive-white-text);
        svg {
            fill: var(--interactive-white-text);
            path {
                fill: var(--interactive-white-text);
            }
        }
        &::before {
            background-color: var(--interactive-white);
        }

        &:focus {
            &::after {
                border: 2px solid var(--interactive-white_border);
            }
        }

        &:hover::before {
            background-color: var(--interactive-white_hover);
        }

        &:active {
            &::before {
                background-color: var(--interactive-white_active);
            }
        }
    }

    &.header {
        color: var(--interactive-white-text);
        svg {
            fill: var(--interactive-white-text);
            path {
                fill: var(--interactive-white-text);
            }
        }
        border: 1px solid var(--interactive-white-text);
        color: var(--interactive-white-text);
        svg, path {
            fill: var(--interactive-white-text);
        }
        &::before {
            background-color: var(--interactive-white);
        }
    
        &:focus {
            border: 1px solid var(--interactive_border);
            &::after {
                border: 1px solid var(--interactive_border);
            }
        }
    
        &:hover::before {
            background-color: var(--interactive-white_hover);
        }
    
        &:active {
            &::before {
                background-color: var(--interactive-white_active);
            }
        }
    }
    &.no-border {
        border: 1px solid transparent;
    }
    &.disabled, &:disabled {
        cursor: not-allowed;
        background-color: var(--layer-1_bg);
        color: var(--text_disabled);
        svg,
        path {
            fill: var(--text_disabled);
        }
        &::before {
            background-color: var(--layer-1_bg);
            cursor: not-allowed;
        }

        &:active,
        &:hover,
        &:focus {
            &::after {
                border: 0
            }
            &::before {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--layer-1_bg);
            }
        }
    }
}
@use '../styles/Typography.module.scss';
@use '../styles/Breakpoints.module.scss';

.menu-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: var(--layer-1_bg);
}

.menu-wrapper {
    padding-top: var(--hero-padding-top);
    padding-bottom: 6rem;
}

.menu-sub-title-container {
    margin-bottom: 1rem;
}

.menu-sub-containers {
    margin-top: 2rem;
    .menu-sub-container {
        margin-bottom: 2rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.menu-sub-container {
    p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: Breakpoints.$breakpoint-2) {
    .menu-modal {
        height: calc(100% - 55px);
    }
    .menu-wrapper {
        padding: var(--padding-top) 0;
    }
}
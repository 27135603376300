@use '../styles/Themes.module.scss';
@use '../styles/Breakpoints.module.scss';

.footer-mouse-follower {
    height: 64px;
    width: 64px;
    position: fixed;
    z-index: 1;
    margin-top: 12px;
    margin-left: -72px;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s linear opacity, 0.2s linear visibility;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.footer-container {
    background: var(--layer-contrast_bg);
    color: var(--layer-contrast_text);
    padding: var(--padding-bottom) 0;
    @include Themes.white-links;
    @include Themes.light-contrast-selection;
}

.footer-header {
    font-weight: 600;
    margin-bottom: 1rem;
}

.footer-links {
    & > li {
        margin-bottom: 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.footer-splitter {
    margin: 2rem 0;
}

@media screen and (max-width: Breakpoints.$breakpoint-2) {
    .footer-container {
        padding-bottom: 5rem;
    }
}
@use '../styles/Typography.module.scss';
@use '../styles/Breakpoints.module.scss';
@use '../styles/Themes.module.scss';

.header {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    &.logo-wrapper.scrolled {
        mix-blend-mode: difference;
    }
    :global(.content-container) {
        padding: 0;
    }
    svg {
        transition: 0.2s linear fill, 0.2s linear stroke;
        display: block;
        fill: currentColor;
        path {
            transition: 0.2s linear fill, 0.2s linear stroke;
            fill: var(--white-1);
        }
    }
}

.mobile-header-wrapper {
    display: none;
    z-index: 100;
    position:fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--interactive-white);
    border-top: 1px solid var(--accent-1);
}
.mobile-header-container {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 var(--padding-lr);
    .mobile-header-section {
        display: flex;
        align-items: center;
    }
    .logo-link {
        margin-top: 0.25rem;
        display: inline-block;
        svg {
            height: 1.75rem;
        }
    }
}
.mobile-header-actions .mobile-header-action{
    position: relative;
    :global(.link){
        padding: 0 1rem;
        height: 3.375rem;
        display: flex;
        align-items: center;
    }
    &::before, &:first-of-type::after {
        position: absolute;
        height: 100%;
        width: 1px;
        right: 0;
        top: 0;
        content: ' ';
        display: block;
        background-color: var(--accent-1);
    }
    &:first-of-type::after {
        right: initial;
        left: 0;
    }
    &:last-of-type::before {
        display:none; 
    }
}

.header-logo-container {
    z-index: 101;
    position: absolute;
    padding: 0;
    .logo-link-square {
        display: block;
        width: 7.375rem;
        height: 7.375rem;
        background-color: var(--brand);
        transition: 0.25s ease-out background-color;
        @include Themes.logo-link;
        &:hover {
            background-color: var(--link_hover);
        }
        &:active {
            background-color: var(--link_active);
        }
    }
    .header-logo {
        visibility: visible;
    }
    .animated-header-logo {
        position: absolute;
        display: none;
        top: 1.5rem;
        height: 130px;
    }
    .logo-link {
        display: block;
        position: relative;
        top: 0.875rem;
        left: 0.875rem;
        transition: 0.25s ease-out top, 0.25s ease-out left, 0.25s ease-out padding;
        .header-logo {
            transition: 0.25s ease-out height, 0.25s ease-out width;
            height: 2.7rem;
        }
    }
    &.scrolled {
        .logo-link {
            padding: 4rem 0 0 0;
            top: 0;
            left: 0;
            .header-logo {
                height: 3.75rem;
            }
            &:hover {
                .header-logo {
                    visibility: hidden;
                }
                .animated-header-logo {
                    display: block;
                }
            }
        }
        .logo-link-square {
            background-color: transparent;
            &:hover, &:active {
                background-color: transparent;
            }
        }
    }
    
}

.header-menu-container {
    display: flex;
    z-index: 101;
    position: absolute;
    margin-top: 4rem;
    right: 0;
    & > div {
        margin-right: 1px;
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.header-contents-container {
    position: relative;
    margin: 0 2rem;
}

@media screen and (max-width: Breakpoints.$breakpoint-2) {
    .header {
        display: none;
    }
    .mobile-header-wrapper {
        display: block;
    }
}


.page-section-nav::before {
    background-color: var(--layer-1_bg);
    height: 100%;
}

.scroll-progress {
    background-color: var(--brand);
    transition: 0.2s ease-out height;
}

.page-section-nav {
    position: absolute;
    left: 6rem;
    top: var(--padding-top);
    opacity: 1;
    visibility: visible;
    transition: 0.25s opacity ease-out, 0.25s visibility ease-out;
    z-index: 97;
    transition: 0.3s ease-out margin-top;
    border: 1px solid var(--accent-1);
    li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--accent-1);
        &:last-of-type {
            border-bottom: 0;
            a {
                margin-bottom: 0;
            }
        }
        &:hover .title{
            opacity: 1;
        }
    }
    .title {
        background-color: var(--bg);
        position: absolute;
        left: 4rem;
        opacity: 0;
        padding: 0.25rem;
        width: 8rem;
        transition: 0.25s opacity linear;
    }
    button {
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width:3rem;
        height:3rem;
        color: var(--interactive-white-text);
        background-color: var(--interactive-white);
        transition: 0.2s linear background-color;
        &:hover {
            background-color: var(--interactive-white_hover);
        }
        &:active {
            background-color: var(--interactive-white_active);
        }
        &.active {
            color: var(--white-1);
            background-color: var(--brand);
        }
    }

    &.fixed {
        position: fixed;
        top: 13rem;
        left: 6rem;
    }

    &.override {
        position: absolute;
        margin-top: 0;
    }
}

.page-section-nav-mobile {
    display: none;
    width: calc(100% - (var(--padding-lr) * 2));
    position: absolute;
    box-shadow: 0 0 8px var(--nav-shadow);
    z-index: 4;
    margin-top: -1.75rem;
    transition: 0.25s left ease-out, 0.25s width ease-out;
    border-bottom: none;
    left: var(--padding-lr);

    &.fixed {
        position: fixed;
        bottom: auto;
        top: 0;
        left: 0;
        margin-top: 0;
        width: 100%;
    }

    .page-section-nav-mobile-progress-bar {
        transition: 0.2s linear width;
        height: 1px;
        position: absolute;
        bottom: 0;
        width: 10%;
        background-color: var(--brand);
    }
}

.content-wrapper {
    position: relative;
}

@media screen and (max-width: 1024px) {
    .page-section-nav {
        display: none;
    }

    .page-section-nav-mobile {
        display: block;
    }
}
@use '../styles/Breakpoints.module.scss';

.splitter-container {
    display: flex;
    align-items: center;

    .hero-margin {
        margin-top: 4rem;
    }

    span {
        display: inline-block;
        width: 2.5rem;
        font-weight: 600;
        color: var(--link);
    }
}

.splitter {
    width: 100%;
    transition: 0.5s ease-out width;
    border-top: 1px solid var(--accent-1);

    &.thick {
        border-top: 0.625rem solid var(--brand);
    }

    &.animated {
        transition: 0.5s ease-out width;
        width: 1px;

        &.active {
            width: 100%;

            &.text {
                width: calc(100% - 2.5rem);
            }
        }
    }
}

@media screen and (max-width: Breakpoints.$breakpoint-3) {
    .hide-on-mobile {
        display: none;
    }
}
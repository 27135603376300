@mixin displaySerif {
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
    letter-spacing: initial;
    letter-spacing: normal;
}

@mixin quoteSerif {
    font-family: 'IBM Plex Serif', serif;
    font-weight: 300;
    letter-spacing: normal;
}

@mixin hero {
    letter-spacing: -0.2rem;
    font-size: var(--size_hero);
    font-weight: 400;
}

@mixin homeHero {
    letter-spacing: -0.1rem;
    font-size: var(--size_hero);
    line-height: 110%;
    font-weight: 200;
}

@mixin xxl {
    letter-spacing: -0.0625rem;
    font-size: var(--size_xxl);
    line-height: 125%;
    font-weight: 300;
}

@mixin xl {
    letter-spacing: normal;
    font-size: var(--size_xl);
    font-weight: 300;
}

@mixin l {
    letter-spacing: normal;
    font-size: var(--size_l);
    font-weight: 400;
}

@mixin base {
    letter-spacing: var(--letter-spacing_base);
    font-size: var(--size_base);
    font-weight: 400;
}

@mixin label {
    letter-spacing: 0.32px;
    font-size: var(--size_label);
    font-weight: 400;
}

@mixin button {
    letter-spacing: var(--letter-spacing_base);
    font-size: var(--size_base);
}

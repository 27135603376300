.typer-container {
    position: relative;
    :global(.Typewriter) {
        position: absolute;
    }
    :global(.Typewriter__wrapper) {
        transition: 0.15s background-color linear, 0.15s color linear;
    }
}

.ghost {
    opacity: 0;
    visibility: hidden;
}

.typing {
    :global(.Typewriter__wrapper) {
        background-color: var(--brand);
        color: var(--layer-contrast_text);
    }
}